.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* background */
.gradient {
  background: linear-gradient(
    180deg,
    #eaf3ff 39.7%,
    rgba(234, 243, 255, 0) 98.97%
  );
}

.orange-gradient {
  background: linear-gradient(90deg, #faad98 0%, #f88478 100%);
}

.blue-gradient {
  /* Linear */
  background: linear-gradient(270deg, #5e94cd 0%, #81b3e3 100%);
  border-radius: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* fonts */
@import url("https://fonts.googleapis.com/css?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter&display=swap");

.font-dm-sans{
  font-family: "DM Sans", sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-dm-sans-h1 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  /* or 114% */
  letter-spacing: 0.01em;
}
.font-dm-sans-h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
  /* or 114% */
  letter-spacing: 0.01em;
  margin-right: 20px;
}




.font-inter-p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 31px;
  /* or 148% */

  letter-spacing: 0.01em;
}
