.circle {
    display: block;
    text-align: center;
    align-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-position: center;
    border: 1px solid #5388C1;
    background-repeat: no-repeat;
    /* width and height can be anything, as long as they're equal */
}
